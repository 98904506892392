<template>
  <div>
    <v-container>
      <v-row>
        <v-col
          cols="12"
        >
          <v-card
            rounded
            dark
          >
            <v-card-text>
              <v-container>
                <v-row
                  v-for="item in newsItems"
                  :key="item.key"
                  :class="item.key > 1 ? 'mt-3': 'mt-0'"
                >
                  <v-col>
                    <div>
                      <span
                        class="font-weight-bold text-no-wrap"
                        style="color: #E1AE05"
                      >
                        {{ item.date }}
                      </span>
                      <div>
                        {{ item['news-synopsis'] }}
                      </div>
                      <a :href="item['news-item-url']">
                        <v-img
                          :src="item['news-site-src']"
                          :title="item['hover-message']"
                        />
                      </a>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
  export default {
    data: () => ({
      newsItems: [
        {
          'key': 1,
          'date': 'August 9th, 2024',
          'news-site-src': 'https://hips.hearstapps.com/vidthumb/45932c39-5985-4c07-af12-3c4ec85cc373/7f58a2bd-257a-4ab0-bbe0-d660d76c8746.jpg?crop=1xw%3A1.0xh%3Bcenter%2Ctop&resize=810%3A*&quality=70',
          'news-item-url': 'https://www.wxii12.com/article/north-carolina-local-bands-to-watch-goryango/61826591',
          'news-title': 'GOryanGO on #TheLocalVibe',
          'news-synopsis': 'GOryanGO featured in \'Local Bands to Watch\' on WXII 12. Matt and Christian talk about the band, America\'s Next Top Hitmaker contest, and upcoming shows',
          'hover-message': 'Click for more info'
        },
        {
          'key': 2,
          'date': 'February 7th, 2024',
          'news-site-src': '/static/yes-weekly-20240207.png',
          'news-item-url': 'https://www.yesweekly.com/music/goryango-gets-one-step-closer/article_e24e8b94-c5c6-11ee-a679-831922e6dd33.html',
          'news-title': 'GOryanGO in YES! Weekly',
          'news-synopsis': 'GOryanGO was featured in an artist spotlight in YES! Weekly. The band, the latest EP, \'One Step Closer\' and upcoming shows were discussed',
          'hover-message': 'Click for more info'
        }
      ]
    })
  }
</script>

<style scoped>
  .fade-transition-leave,
  .fade-transition-leave-active,
  .fade-transition-leave-to {
    display: none;
  }
</style>
